<!--
 * @Author: 蒋威
 * @Date: 2022-04-26 09:08:46
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-14 10:33:33
 * @Description: 轮播图
-->
<template>
  <el-carousel
    :interval="5000"
    indicator-position="none"
    arrow="always"
    v-if="bannerList.length > 0"
  >
    <el-carousel-item v-for="(item, index) in bannerList" :key="index">
      <img
        @click="gotojump(item)"
        class="carousel-img"
        v-lazy="item.file_url"
        alt=""
        srcset=""
        :class="item.url ? 'pointer' : ''"
      />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { websiteAds_list } from '@/api/homePage/index.js'
export default {
  name: 'elRotation',
  props: {
    modelId: {
      type: Array | String,
    },
  },
  data() {
    return {
      bannerList: [
        {
          imageUrl:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        },
        {
          imageUrl:
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        },
      ],
      // 页面可视区高度
      clientHeight: '',
    }
  },
  methods: {
    // 获取轮播
    async getBannerList() {
      const res = await websiteAds_list({
        banner_type: 1,
        page: 1,
        limit: 5,
      })
      if (res.code === this.$httpCode) {
        this.bannerList = res.data.data && res.data.data.slice(0, 5)
      }
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-04-19 16:44:18
     * @Description: 轮播图跳转
     */
    gotojump(item) {
      if (item.url) {
        window.open(item.url, '_blank')
      }
    },
  },
  created() {
    this.getBannerList()
  },
}
</script>

<style lang="scss" scoped>
.carousel-img {
  width: 100%;
  height: 100%;
}
.el-carousel {
  height: 52vw;
  ::v-deep .el-carousel__container {
    height: 100%;
  }
  ::v-deep .el-carousel__arrow.el-carousel__arrow--left {
    left: 0;
    width: 101px;
    height: 92px;
    background: #e3a63b;
    border-radius: 0;
    .el-icon-arrow-left {
      font-size: 60px;
    }
  }
  ::v-deep .el-carousel__arrow.el-carousel__arrow--right {
    right: 0;
    width: 101px;
    height: 92px;
    background: rgba($color: #4f504b, $alpha: 0.6);
    border-radius: 0;
    .el-icon-arrow-right {
      font-size: 60px;
      color: rgba($color: #fff, $alpha: 0.6);
    }
  }
}
</style>