<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 14:46:24
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-16 17:05:46
 * @Description: 首页-太阳项目 
-->
<template>
  <div class="home-news-container">
    <div class="response home-news-wrap">
      <!-- 左侧 -->
      <div class="home-news-left">
        <div class="news-englable">Sun</div>
        <br />
        <div class="news-englable border">Project</div>
        <div class="news-title-wrap">
          <img src="~@/assets/img/home/logo-foot 3.png" alt="" />
          <span>太阳项目</span>
        </div>
        <!-- 跳转 -->
        <div class="jump-wrap">
          <i
            class="iconfont icon-jiantou_xiangyou_o pointer"
            @click="toProject"
          ></i>
        </div>
      </div>
      <div class="home-news-right">
        <div class="project-wrap">
          <template v-for="(item, inex) in projectList">
            <div
              class="project-item pointer"
              @click="toProject(item)"
              :key="inex"
              v-if="inex < 4"
            >
              <img v-lazy="item.url" alt="" />
              <div class="project-explain" v-if="item.name">
                {{ item.name }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { channelTree } from '@/api/menu/index.js'
import { homeProject } from '@/api/homePage/index.js'
export default {
  inject: ['reload'],
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      projectList: [],
      // 项目id
      moduleId: null,
    }
  },
  methods: {
    async getHomeProject() {
      const res = await homeProject({ limit: 4, page: 1 })
      if (res.code === this.$httpCode) {
        this.projectList = res.data.data
      }
      console.log(res.data, this.projectList, 'projectList')
    },
    getModuleId() {
      for (let index = 0; index < this.menuList.length; index++) {
        if (
          this.menuList[index].model == 'project' &&
          this.menuList[index].title.indexOf('项目') != -1
        ) {
          this.moduleId = this.menuList[index].id
          break
        }
      }
      if (this.moduleId === null) {
        for (let index = 0; index < this.menuList.length; index++) {
          if (this.menuList[index].model == 'project') {
            this.moduleId = this.menuList[index].id
            break
          }
        }
      }
    },
    toProject(item) {
      if (this.menuList.length > 0) {
        this.getModuleId()
        let query = {}
        if (item && item.id) {
          query = { moduleId: this.moduleId || 55, id: item.id }
        } else {
          query = { moduleId: this.moduleId || 55 }
        }
        this.reload()
        this.$router.push({
          name: 'project',
          query: query,
        })
      } else {
        this.getMenuList().then((res) => {
          let query = {}
          if (item && item.id) {
            query = { moduleId: this.moduleId || 55, id: item.id }
          } else {
            query = { moduleId: this.moduleId || 55 }
          }
          this.reload()
          this.$router.push({
            name: 'project',
            query: query,
          })
        })
      }
    },
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        for (let index = 0; index < res.data.length; index++) {
          if (
            res.data[index].model == 'project' &&
            res.data[index].title.indexOf('项目') != -1
          ) {
            this.moduleId = res.data[index].id
            break
          }
        }
        if (this.moduleId === null) {
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].model == 'project') {
              this.moduleId = res.data[index].id
              break
            }
          }
        }
      }
    },
  },
  created() {
    this.getHomeProject()
  },
}
</script>

<style lang="scss" scoped>
.home-news-container {
  height: 952px;
  // height: 1266px;
  width: 100%;
  background: #fff;
  .home-news-wrap {
    display: flex;
    height: 100%;
    padding: 100px 0;
    .home-news-left {
      flex: 0 0 240px;
      width: 240px;
      margin-right: 20px;
      height: 100%;
      position: relative;
      .news-englable {
        font-size: 46px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: rgba($color: #a09c9c, $alpha: 1);
        line-height: 54px;
        height: 68px;
        display: inline-block;
        letter-spacing: 1px;
        &.border {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 50px;
            height: 4px;
            background: #e3a63b;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 30px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #e3a63b;
          }
        }
      }
      .news-title-wrap {
        display: flex;
        margin-top: 20px;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }
        span {
          height: 45px;
          font-size: 34px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #242121;
          line-height: 40px;
        }
      }

      .jump-wrap {
        position: absolute;
        bottom: 10px;
        i {
          width: 56px;
          height: 56px;
          background: #e3a63b;
          display: inline-block;
          border-radius: 50%;
          text-align: center;
          line-height: 56px;
          font-size: 30px;
          color: #fff;
          &:hover {
            font-size: 36px;
            transition: all 0.2s;
          }
        }
      }
    }

    .home-news-right {
      flex: 1;
      height: 100%;
      .project-wrap {
        display: flex;
        height: 100%;
        flex-wrap: wrap;
        .project-item {
          height: 376px;
          // height: 533px;
          width: 470px;
          flex: 0 0 470px;
          -ms-flex: 0 0 468px;
          border: 1px solid #ffffff;
          &:nth-child(3),
          &:nth-child(4) {
            border-top: none;
          }
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .project-explain {
            display: none;
            position: absolute;
            bottom: 24px;
            width: 100%;
            padding: 0 6px;
            text-align: center;
            font-size: 20px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
          }
          &:hover{
             .project-explain {
               display: block;
             }
          }
        }
      }
    }
  }
}
</style>