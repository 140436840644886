<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 14:46:24
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-17 16:42:18
 * @Description: 首页-太阳排行榜  
-->
<template>
  <div class="home-news-container">
    <div class="response home-news-wrap">
      <!-- 左侧 -->
      <div class="home-news-left">
        <div class="news-englable">Sun</div>
        <br />
        <div class="news-englable border">Ranking List</div>
        <div class="news-title-wrap">
          <img src="~@/assets/img/home/logo-foot 3-1.png" alt="" />
          <span>太阳排行榜</span>
        </div>
      </div>
      <div class="home-news-right">
        <div class="ranking-wrap">
          <div class="ranking-title">志愿者排行榜</div>
          <div class="ranking-category-wrap">
            <span
              class="ranking-category-left pointer ranking-category-item"
              :class="rankingType == 'service_length' ? 'active' : ''"
              @click="changType('service_length')"
            >
              服务时长
            </span>
            <span
              class="ranking-category-right pointer ranking-category-item"
              :class="rankingType == 'integral' ? 'active' : ''"
              @click="changType('integral')"
            >
              积分排行
            </span>
          </div>
          <!-- 服务时长 -->
          <div class="rankingList-wrap">
            <template v-for="(item, index) in rankingList">
              <div
                class="rankingList-item"
                :key="index"
                :class="index < 3 ? 'badge' : ''"
                v-if="index < 8"
              >
                <div class="rankingList-sort">
                  <img
                    v-if="index == 0"
                    src="~@/assets/img/home/Frame.png"
                    alt=""
                  />
                  <img
                    v-if="index == 1"
                    src="~@/assets/img/home/Frame1.png"
                    alt=""
                  />
                  <img
                    v-if="index == 2"
                    src="~@/assets/img/home/Frame2.png"
                    alt=""
                  />
                  <span v-if="index > 2">{{ '0' + (index + 1) }}</span>
                </div>
                <span class="rankingList-item-name">
                  {{ item.volunteer_name }}
                </span>
                <span class="duration">
                  {{
                    rankingType == 'service_length'
                      ? item.service_length+'h'
                      : item.integral+"分"
                  }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { webrank } from '@/api/homePage/index.js'
export default {
  data() {
    return {
      // 排行类型 service_length时长  integral积分
      rankingType: 'service_length',
      // 排行榜数据
      rankingList: [],
    }
  },
  methods: {
    changType(type) {
      this.rankingType = type
      this.getWebrank()
    },
    async getWebrank() {
      const res = await webrank({
        order_by: this.rankingType,
        limit: 8,
      })
      if (res.code === this.$httpCode) {
        this.rankingList = res.data
      }
      console.log(this.rankingList, 'sdtg')
    },
  },
  created() {
    this.getWebrank()
  },
}
</script>

<style lang="scss" scoped>
.home-news-container {
  height: 1066px;
  width: 100%;
  background-size: 100% 100%;
  background-image: url('~@/assets/img/home/Frame 142.png');
  .home-news-wrap {
    display: flex;
    height: 100%;
    padding: 100px 0;
    .home-news-left {
      flex: 0 0 300px;
      width: 300px;
      margin-right: 20px;
      height: 100%;
      position: relative;
      .news-englable {
        font-size: 46px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: rgba($color: #ffffff, $alpha: 1);
        line-height: 54px;
        height: 68px;
        display: inline-block;
        letter-spacing: 1px;
        &.border {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 50px;
            height: 4px;
            background: #fff;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 30px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #fff;
          }
        }
      }
      .news-title-wrap {
        display: flex;
        margin-top: 20px;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }
        span {
          height: 45px;
          font-size: 34px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 40px;
        }
      }

      .jump-wrap {
        position: absolute;
        bottom: 10px;
        i {
          width: 56px;
          height: 56px;
          background: #e3a63b;
          display: inline-block;
          border-radius: 50%;
          text-align: center;
          line-height: 56px;
          font-size: 30px;
          color: #fff;
        }
      }
    }

    .home-news-right {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      .ranking-wrap {
        width: 601px;
        height: 789px;
        padding: 59px 66px;
        background-size: 100% 100%;
        background-image: url('~@/assets/img/home/Frame 131.png');
        .ranking-title {
          height: 52px;
          font-size: 40px;
          font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 47px;
          text-align: center;
        }
        .ranking-category-wrap {
          width: 324px;
          margin: 0 auto;
          height: 48px;
          border-radius: 22px 22px;
          border: 2px solid #ffffff;
          background-color: #fff;
          margin-top: 34px;
          .ranking-category-left {
            // background-color: #fff;
            display: inline-block;
            width: 160px;
            height: 44px;
            font-size: 20px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #e3a63b;
            line-height: 44px;
            text-align: center;
            border-top-left-radius: 22px;
            border-bottom-left-radius: 22px;
          }
          .ranking-category-right {
            display: inline-block;
            height: 44px;
            width: 160px;
            font-size: 20px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 44px;
            text-align: center;
            border-top-right-radius: 22px;
            border-bottom-right-radius: 22px;
          }
          .ranking-category-item {
            background-color: #e3a63b;
            color: #ffffff;
            &.active {
              background-color: #fff;
              color: #e3a63b;
            }
          }
        }

        .rankingList-wrap {
          width: 469px;
          height: 498px;
          margin-top: 42px;
          background: #ffffff;
          border-radius: 20px 20px 20px 20px;
          padding: 40px 40px;
          .rankingList-item {
            height: 22px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            padding: 0 22px 0 7px;
            justify-content: space-between;
            .rankingList-sort {
              width: 30px;
              height: 22px;
              text-align: center;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #e3a63b;
              line-height: 22px;
            }
            .rankingList-item-name {
              height: 22px;
              margin: 0 20px 0 46px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #242121;
              line-height: 20px;
              flex: 1;
            }
            .duration {
              height: 22px;
              font-size: 16px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #f0b90b;
              line-height: 22px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &.badge {
              height: 41px;
              margin-bottom: 20px;
              .rankingList-sort {
                height: 41px;
                img {
                  width: 30px;
                  height: 41px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>