<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 14:46:24
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-19 15:30:46
 * @Description: 首页-太阳新闻
-->
<template>
  <div class="home-news-container">
    <div class="response home-news-wrap">
      <!-- 左侧 -->
      <div class="home-news-left">
        <div class="news-englable">Sun</div>
        <br />
        <div class="news-englable border">Activity</div>
        <div class="news-title-wrap">
          <img src="~@/assets/img/home/logo-foot 3-1.png" alt="" />
          <span>太阳活动</span>
        </div>
        <!-- 跳转 -->
        <div class="jump-wrap">
          <i
            class="iconfont icon-jiantou_xiangyou_o pointer"
            @click="toActivity"
          ></i>
        </div>
      </div>
      <div class="home-news-right">
        <!-- 问卷调查 -->
        <div
          class="questionnaire-wrap pointer"
          v-if="activityList.length > 0"
          @click="toActivity(activityList[0])"
        >
          <div class="questionnaire-state">
            <img v-lazy="activityList[0].url" alt="" />
            <div
              class="state-lable"
              :class="setStateClass(activityList[0].activity_status)"
            >
              活动{{ activityList[0].activity_status_text }}
            </div>
          </div>
          <div class="questionnaire-content">
            <div class="questionnaire-title ellipsis-2">
              {{ activityList[0].name }}
            </div>
            <div class="questionnaire-bottom">
              <div class="questionnaire-text ellipsis-2">
                {{ activityList[0].address }}
              </div>
              <div class="questionnaire-time">
                {{ $getDate(activityList[0].sign_up_start, 'M') }}月
                {{ $getDate(activityList[0].sign_up_start, 'D') }}日
              </div>
            </div>
          </div>
        </div>
        <!-- 活动 -->
        <div class="activity-wrap">
          <template v-for="(item, index) in activityList">
            <div
              class="activity-item pointer"
              :key="index"
              v-if="index < 4 && index > 0"
              @click="toActivity(item)"
            >
              <div class="img-wrap">
                <img v-lazy="item.url" alt="" />
                <!-- <img src="~@/assets/img/home/Frame 135.png" alt="" /> -->
                <div
                  class="activity-state-lable"
                  :class="setStateClass(item.activity_status)"
                >
                  活动{{ item.activity_status_text }}
                </div>
              </div>
              <div class="activity-info-wrap">
                <div class="activity-info-title ellipsis-2">
                  {{ item.name }}
                  <!-- {{ item.title }} -->
                </div>
                <div class="activity-info-addr ellipsis-4">
                  {{ item.address }}
                  <!-- {{ item.addr }} -->
                </div>
                <div class="activity-info-tiem">
                  <span>
                    {{ $getDate(item.sign_up_start, 'M') }}月
                    {{ $getDate(item.sign_up_start, 'D') }}日
                  </span>
                  <!-- <span>{{ item.tiem }}</span> -->
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activityList } from '@/api/activity/index.js'
import { homeActivitys } from '@/api/homePage/index.js'
import { channelTree } from '@/api/menu/index.js'
export default {
  inject: ['reload'],
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      activityList: [],
      moduleId: null,
    }
  },
  methods: {
    // 设置活动类名
    setStateClass(state) {
      switch (state) {
        // 未开始
        case 1:
          return 'notStarted'
          break
        // 进行中
        case 2:
          return 'conduct'
          break
        // 结束
        case 3:
          return 'end'
          break

        default:
          return ''
          break
      }
    },
    async homeActivitys() {
      const res = await activityList({
        limit: 4,
        page: 1,
        if_show: 1,
        if_recommend: 2,
      })
      if (res.code === this.$httpCode) {
        this.activityList = res.page.data
      }
    },
    toActivity(item) {
      if (this.menuList.length > 0) {
        this.getModuleId()
        this.reload()
        let query = {}
        if (item && item.id) {
          query = { moduleId: this.moduleId || 53, id: item.id }
        } else {
          query = { moduleId: this.moduleId || 53 }
        }
        this.$router.push({
          name: 'activity',
          query: query,
        })
      } else {
        this.getMenuList().then((res) => {
          this.reload()
          let query = {}
          if (item && item.id) {
            query = { moduleId: this.moduleId || 53, id: item.id }
          } else {
            query = { moduleId: this.moduleId || 53 }
          }
          this.$router.push({
            name: 'activity',
            query: query,
          })
        })
      }
    },

    getModuleId() {
      for (let index = 0; index < this.menuList.length; index++) {
        if (
          this.menuList[index].model == 'activity' &&
          this.menuList[index].title.indexOf('activity') != -1
        ) {
          this.moduleId = this.menuList[index].id
          break
        }
      }
      if (this.moduleId === null) {
        for (let index = 0; index < this.menuList.length; index++) {
          if (this.menuList[index].model == 'activity') {
            this.moduleId = this.menuList[index].id
            break
          }
        }
      }
    },
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        for (let index = 0; index < res.data.length; index++) {
          if (
            res.data[index].model == 'activity' &&
            res.data[index].title.indexOf('activity') != -1
          ) {
            this.moduleId = res.data[index].id
            break
          }
        }
        if (this.moduleId === null) {
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].model == 'activity') {
              this.moduleId = res.data[index].id
              break
            }
          }
        }
      }
    },
  },
  created() {
    this.homeActivitys()
  },
}
</script>

<style lang="scss" scoped>
.home-news-container {
  height: 1066px;
  width: 100%;
  background: #e3a63b;
  .home-news-wrap {
    display: flex;
    height: 100%;
    padding: 238px 0;
    .home-news-left {
      flex: 0 0 240px;
      width: 240px;
      margin-right: 20px;
      height: 100%;
      position: relative;
      .news-englable {
        font-size: 46px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: rgba($color: #fff, $alpha: 0.8);
        line-height: 54px;
        height: 68px;
        display: inline-block;
        letter-spacing: 1px;
        &.border {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 50px;
            height: 4px;
            background: #fff;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 30px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #fff;
          }
        }
      }
      .news-title-wrap {
        display: flex;
        margin-top: 20px;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }
        span {
          height: 45px;
          font-size: 34px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #fff;
          line-height: 40px;
        }
      }

      .jump-wrap {
        position: absolute;
        bottom: 10px;
        i {
          width: 56px;
          height: 56px;
          background: #fff;
          display: inline-block;
          border-radius: 50%;
          text-align: center;
          line-height: 56px;
          font-size: 30px;
          color: #e3a63b;
          &:hover {
            background-color: #fff;
            color: #f05330;
            font-size: 36px;
            transition: all 0.2s;
          }
        }
      }
    }

    .home-news-right {
      flex: 1;
      height: 100%;
      display: flex;
      .questionnaire-wrap {
        flex: 0 0 458px;
        width: 458px;
        // flex: 0 0 448px;
        // width: 448px;
        margin-right: 44px;
        background-color: #fff;
        &:hover {
          background-color: #89ba29;
          .questionnaire-content {
            .questionnaire-title {
              color: #fff;
            }
            .questionnaire-bottom {
              .questionnaire-text {
                color: #fff;
              }
              .questionnaire-time {
              }
            }
          }
        }
        .questionnaire-state {
          width: 100%;
          height: 426px;
          // height: 456px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          // background-image: url('~@/assets/img/home/Frame 134.jpg');
          img {
            width: auto;
            height: 100%;
            // max-width: 100%;
            // max-height: 100%;
          }
          .state-lable {
            position: absolute;
            top: 0;
            right: 0;
            width: 150px;
            text-align: center;
            background: #f8b92b;
            height: 34px;
            padding: 4px 0;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: rgba($color: #fff, $alpha: 1);
            line-height: 26px;
            &.conduct {
              background: #f8b92b;
            }
            &.notStarted {
              background: #f8682b;
            }
            &.end {
              background: #e6e6e6;
            }
          }
        }
        .questionnaire-content {
          width: 100%;
          height: 164px;
          padding: 40px 20px 20px 20px;
          .questionnaire-title {
            height: 40px;
            font-size: 16px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #e3a63b;
            line-height: 20px;
          }
          .questionnaire-bottom {
            display: flex;
            margin-top: 14px;
            .questionnaire-text {
              flex: 1;
              padding-right: 10px;
              height: 32px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #e3a63b;
              line-height: 16px;
              margin-top: 6px;
            }
            .questionnaire-time {
              flex: 0 0 78px;
              display: inline-block;
              height: 30px;
              background: #f05330;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              padding: 6px 0px;
              text-align: center;
              color: #ffffff;
              line-height: 18px;
              width: 77px;
            }
          }
        }
      }

      .activity-wrap {
        height: 100%;
        flex: 0 0 438px;
        width: 438px;
        // flex: 0 0 448px;
        // width: 448px;
        .activity-item {
          margin-bottom: 40px;
          display: flex;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background-color: #89ba29;
          }
          .img-wrap {
            width: 253px;
            // width: 263px;
            height: 169px;
            margin-right: 20px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .activity-state-lable {
              position: absolute;
              right: 0;
              top: 0;
              width: 150px;
              text-align: center;
              background: #f8b92b;
              height: 34px;
              padding: 4px 0;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba($color: #fff, $alpha: 1);
              line-height: 26px;
              &.conduct {
                background: #f8b92b;
              }
              &.notStarted {
                background: #f8682b;
              }
              &.end {
                background: #e6e6e6;
                color: #414141;
              }
            }
          }
          .activity-info-wrap {
            flex: 1;
            .activity-info-title {
              margin-top: 6px;
              height: 48px;
              font-size: 16px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
              line-height: 24px;
            }
            .activity-info-addr {
              margin: 8px 0;
              height: 64px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 16px;
            }
            .activity-info-tiem {
              span {
                display: inline-block;
                height: 30px;
                line-height: 30px;
                background: #f05330;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                width: 78px;
                text-align: center;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
</style>