<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 14:46:24
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-20 20:35:06
 * @Description: 首页-太阳新闻
-->
<template>
  <div class="home-news-container">
    <div class="response home-news-wrap">
      <!-- 左侧 -->
      <div class="home-news-left">
        <div class="news-englable">Sun</div>
        <br />
        <div class="news-englable border">News</div>
        <div class="news-title-wrap">
          <img src="~@/assets/img/home/logo-foot 3.png" alt="" />
          <span>太阳新闻</span>
        </div>
        <!-- 跳转 -->
        <div class="jump-wrap">
          <i
            class="iconfont icon-jiantou_xiangyou_o pointer"
            @click="toNews"
          ></i>
        </div>
      </div>
      <div class="home-news-right">
        <div class="news-info-wrap">
          <template v-for="(item, index) in newsList">
            <div
              class="news-info-item pointer"
              :key="index"
              v-if="index < 6"
              @click="toDetail(item)"
            >
              <div class="news-info-tiltebox">
                <div class="time-wrap">
                  <div class="time-month">
                    {{ $getDate(item.created_at, 'M') }}.{{
                      $getDate(item.created_at, 'D')
                    }}
                  </div>
                  <div class="time-year">
                    {{ $getDate(item.created_at, 'Y') }}
                  </div>
                </div>
                <div class="news-info-tilte ellipsis-2">
                  {{ item.title }}
                </div>
              </div>
              <!-- 简介 -->
              <div class="brief-wrap">
                <div class="brief-left"></div>
                <div class="brief-right ellipsis-2">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeNews } from '@/api/homePage/index.js'
import { channelTree } from '@/api/menu/index.js'
export default {
  inject: ['reload'],
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      newsList: [],
      // 新闻id
      moduleId: null,
    }
  },
  methods: {
    async getHomeNews() {
      const res = await homeNews()
      if (res.code === this.$httpCode) {
        this.newsList = res.data
      }
      console.log(this.newsList, 'red')
    },

    /**
     * @Auth: 蒋威
     * @Date: 2022-05-09 09:34:58
     * @Description: 查看活动详情
     */
    toDetail(item) {
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { id: item.id, moduleId: moduleId }
      } else {
        query = { id: item.id }
      }
      const routeData = this.$router.resolve({
        name: 'newsInfo',
        query: query,
      })
      window.open(routeData.href, '_blank')
    },
    getModuleId() {
      for (let index = 0; index < this.menuList.length; index++) {
        if (
          this.menuList[index].model == 'news' &&
          this.menuList[index].title.indexOf('新闻') != -1
        ) {
          this.moduleId = this.menuList[index].id
          break
        }
      }
      if (this.moduleId === null) {
        for (let index = 0; index < this.menuList.length; index++) {
          if (this.menuList[index].model == 'news') {
            this.moduleId = this.menuList[index].id
            break
          }
        }
      }
    },
    async toNews() {
      // const routeData = this.$router.resolve({
      //   name: 'newsList'
      // })
      // window.open(routeData.href, '_blank')
      if (this.menuList.length > 0) {
        this.getModuleId()
        this.reload()
        this.$router.push({
          name: 'news',
          query: { moduleId: this.moduleId || 55 },
        })
      } else {
        this.getMenuList().then((res) => {
          this.reload()
          this.$router.push({
            name: 'news',
            query: { moduleId: this.moduleId || 55 },
          })
        })
      }
    },
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        for (let index = 0; index < res.data.length; index++) {
          if (
            res.data[index].model == 'news' &&
            res.data[index].title.indexOf('新闻') != -1
          ) {
            this.moduleId = res.data[index].id
            break
          }
        }
        if (this.moduleId === null) {
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].model == 'news') {
              this.moduleId = res.data[index].id
              break
            }
          }
        }
      }
    },
  },
  created() {
    this.getHomeNews()
  },
}
</script>

<style lang="scss" scoped>
.home-news-container {
  height: 1066px;
  width: 100%;
  background-size: 100% 100%;
  background-image: url('~@/assets/img/home/Frame 132.jpg');
  .home-news-wrap {
    display: flex;
    height: 100%;
    padding: 183px 0;
    .home-news-left {
      flex: 0 0 240px;
      width: 240px;
      margin-right: 20px;
      height: 100%;
      position: relative;
      .news-englable {
        font-size: 46px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: #a09c9c;
        line-height: 54px;
        height: 68px;
        display: inline-block;
        letter-spacing: 1px;
        &.border {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 16px;
            height: 4px;
            background: #e3a63b;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #e3a63b;
          }
        }
      }
      .news-title-wrap {
        display: flex;
        margin-top: 20px;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }
        span {
          height: 45px;
          font-size: 34px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #242121;
          line-height: 40px;
        }
      }

      .jump-wrap {
        position: absolute;
        bottom: 10px;
        i {
          width: 56px;
          height: 56px;
          background: #e3a63b;
          display: inline-block;
          border-radius: 50%;
          text-align: center;
          line-height: 56px;
          font-size: 30px;
          color: #fff;
          &:hover {
            background-color: #fff;
            color: #e3a63b;
            font-size: 36px;
            transition: all 0.2s;
          }
        }
      }
    }

    .home-news-right {
      flex: 1;
      height: 100%;
      .news-info-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        .news-info-item {
          flex: 0 0 448px;
          -ms-flex: 0 0 408px;
          width: 448px;
          height: 200px;
          padding: 36px 20px;
          background-color: #fff;
          margin-right: 44px;
          margin-bottom: 50px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:hover {
            background-color: #e3a63b;
            .news-info-tiltebox {
              display: flex;

              .time-wrap {
                flex: 0 0 90px;
                margin-right: 6px;
                .time-month {
                  height: 28px;
                  font-size: 30px;
                  font-family: D-DIN-DIN-Bold, D-DIN-DIN;
                  font-weight: bold;
                  color: #242121;
                  line-height: 28px;
                }
                .time-year {
                  height: 14px;
                  margin-top: 8px;
                  font-size: 14px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #a09c9c;
                  line-height: 14px;
                }
              }
              .news-info-tilte {
                width: 312px;
                height: 52px;
                font-size: 16px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #242121;
                line-height: 24px;
              }
            }

            .brief-wrap {
              margin-top: 28px;
              display: flex;
              height: 50px;
              align-items: center;
              .brief-left {
                flex: 0 0 65px;
                margin-right: 16px;
                height: 1px;
                border: 1px solid #a09c9c;
              }
              .brief-right {
                flex: 0 0 282px;
                height: 50px;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #5b5a5a;
                line-height: 25px;
                margin: 0 auto;
              }
            }
            .news-info-tiltebox {
              display: flex;

              .time-wrap {
                .time-month {
                  color: #fff;
                }
                .time-year {
                  color: #fff;
                }
              }
              .news-info-tilte {
                color: #fff;
              }
            }

            .brief-wrap {
              .brief-left {
                border: 1px solid #fff;
              }
              .brief-right {
                color: #fff;
              }
            }
          }
          .news-info-tiltebox {
            display: flex;

            .time-wrap {
              flex: 0 0 90px;
              margin-right: 6px;
              .time-month {
                height: 28px;
                font-size: 30px;
                font-family: D-DIN-DIN-Bold, D-DIN-DIN;
                font-weight: bold;
                color: #242121;
                line-height: 28px;
              }
              .time-year {
                height: 14px;
                margin-top: 8px;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #a09c9c;
                line-height: 14px;
              }
            }
            .news-info-tilte {
              width: 312px;
              height: 52px;
              font-size: 16px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #242121;
              line-height: 24px;
            }
          }

          .brief-wrap {
            margin-top: 28px;
            display: flex;
            height: 50px;
            align-items: center;
            .brief-left {
              flex: 0 0 65px;
              margin-right: 16px;
              height: 1px;
              border: 1px solid #a09c9c;
            }
            .brief-right {
              flex: 0 0 282px;
              height: 50px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5b5a5a;
              line-height: 25px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
</style>