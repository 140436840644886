<!--
 * @Author: 蒋威
 * @Date: 2022-04-07 09:59:39
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-10 09:08:19
 * @Description: 首页
-->
<template>
  <div class="homepage-wrap">
    <!-- 轮播图 -->
    <ElRotation :modelId="homeModelId" v-if="homeModelId" />
    <!-- 新闻 -->
    <Journalism :menuList="menuList" />
    <!-- 活动 -->
    <Activity :menuList="menuList" />
    <!-- 项目 -->
    <project :menuList="menuList" />
    <!-- 太阳排行榜 -->
    <rankingList />
  </div>
</template>

<script>
import ElRotation from './components/ElRotation.vue'
import Journalism from './components/Journalism.vue'
import Activity from './components/Activity.vue'
import project from './components/project.vue'
import rankingList from './components/rankingList.vue'

import { channelTree } from '@/api/menu/index.js'
export default {
  name: 'HomePage',
  components: {
    ElRotation,
    Journalism,
    Activity,
    project,
    rankingList,
  },
  data() {
    return {
      menuList: [],
      // 首页id
      homeModelId: null,
    }
  },
  methods: {
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        this.menuList = res.data
        for (let index = 0; index < this.menuList.length; index++) {
          if (this.menuList[index].model == 'home') {
            this.homeModelId = this.menuList[index].id
          }
        }
      }
    },
  },
  created() {
    this.getMenuList()
  },
}
</script>

<style lang="scss" scoped>
.homepage-wrap {
  width: 100%;
}
</style>
